import { Theme, Grid, Paper, Container } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../setup';
import IMenuItem from '../../../interfaces/common/menuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainLayout from '../../common/widgets/mainLayout';
import LanguageUtils from '../../../utils/LanguageUtils';
import { exportReportLines, fetchReportLines, getReportLines, isLoadingReportLine, resetReportLines } from '../../../reducers/reportsReducer';
import UrlConstants from '../../../constants/UrlConstants';
import Pagination from '../../../interfaces/common/pagination';
import Paging from '../../../interfaces/common/paging';
import { GridColumnVisibilityModel, GridSortDirection, GridSortItem, GridSortModel } from '@mui/x-data-grid';
import PageUtils from '../../../utils/pageUtils';
import ReportLineFilters from '../../../interfaces/filters/reportHeaderLineFilters';
import ReportLineFiltersComponent from './reportLineFilter';
import ReportLineList from './reportLineList';
import ReportLine from '../../../interfaces/output/ReportLine';

interface IReportLineProps {
    classes: any;
    isLoading: boolean;
    onChange: () => void;
    resetReportLines: () => void;
    fetchReportLines: any;
    fetch: any;
    reportLines: Pagination<ReportLine>;
    exportReportLines: any;
    match: any;
}

interface IReportLineState {
    filters: ReportLineFilters;
    menuItem: IMenuItem;
    paging: Paging;
    sortModel: GridSortModel;
    columnVisibilityModel: GridColumnVisibilityModel;
}

const messages = {
    reportLine: LanguageUtils.createMessage('Report line'),
    downloadCSV: LanguageUtils.createMessage('Download CSV')
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    exportReportLines: (filters: ReportLineFilters) => dispatch(exportReportLines({
        filters
    })),
    fetchReportLines: (paging: Paging, filters: ReportLineFilters) => dispatch(fetchReportLines({
        paging,
        filters
    })),
    resetReportLines: () => dispatch(resetReportLines())
});

const mapStoreToProps = (store: RootState) => ({
    isLoading: isLoadingReportLine(store),
    reportLines: getReportLines(store)
});

const styles = (theme: Theme) => createStyles({
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    paper: {
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column'
    }
});

class ReportLines extends Component<IReportLineProps, IReportLineState> {
    reportLineRef: any;
    constructor(props: IReportLineProps) {
        super(props);

        this.state = {
            filters: {} as ReportLineFilters,
            menuItem: {
                id: 1,
                name: messages.reportLine,
                icon: <FontAwesomeIcon icon="table" size="1x" transform="grow-7" />,
                isSelected: false
            },
            paging: PageUtils.getDefaultPaging([{
                field: 'id',
                sort: 'asc'
            } as GridSortItem]),
            sortModel: [{
                field: 'id',
                sort: 'asc' as GridSortDirection
            } as GridSortItem],
            columnVisibilityModel: {}
        };
    }

    componentDidMount() {
        const { fetchReportLines } = this.props;
        const { paging, filters } = this.state;
        const { pipelineGtsOrdersHeaderId } = this.props.match.params;

        const newFilter = {
            ...filters,
            pipelineGtsOrdersHeaderId: pipelineGtsOrdersHeaderId
        } as ReportLineFilters;

        fetchReportLines(paging, newFilter);
    }

    componentWillUnmount() {
        const { resetReportLines } = this.props;
        resetReportLines();
    }

    exportCSV = () => {
        const { exportReportLines } = this.props;
        const { filters } = this.state;

        exportReportLines(filters);
    }

    applyFilters = () => {
        const { fetchReportLines } = this.props;
        const { paging, filters } = this.state;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };
        this.setState({
            paging: newPagination
        });

        fetchReportLines(newPagination, filters);

        if(this.reportLineRef !== null && this.reportLineRef !== undefined &&
            this.reportLineRef.current !== null && this.reportLineRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.reportLineRef.current.resetDataGridPage();
        }
    }

    onChange = (attribute: string, value: any) => {
        const { filters, paging } = this.state;

        const newFilter = { ...filters } as ReportLineFilters;
        (newFilter as any)[attribute] = value;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page
        };

        this.setState({
            filters: newFilter,
            paging: newPagination
        });
    }

    setDefaultState = () => {
        const { fetchReportLines } = this.props;
        const { paging } = this.state;
        const { pipelineGtsOrdersHeaderId } = this.props.match.params;

        const newSortModel = [{
            field: 'id',
            sort: 'asc'
        }] as GridSortModel;

        const newPaging = PageUtils.getDefaultPaging();
        const newPagination = {
            ...paging,
            page: newPaging.page,
            sort: newSortModel
        };

        const newFilters = {
            pipelineGtsOrdersHeaderId: pipelineGtsOrdersHeaderId
        } as ReportLineFilters;

        this.setState({
            filters: newFilters,
            paging: newPagination
        });

        fetchReportLines(newPagination, newFilters);

        if(this.reportLineRef !== null && this.reportLineRef !== undefined &&
            this.reportLineRef.current !== null && this.reportLineRef.current !== undefined) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.reportLineRef.current.resetDataGridPage();
        }
    }

    _onSortChange = (newModel: GridSortModel) => {
        const { fetchReportLines } = this.props;
        const { sortModel, filters, paging } = this.state;

        if(JSON.stringify(sortModel) !== JSON.stringify(newModel)) {
            const newPaging = {
                ...paging,
                sort: newModel
            };

            this.setState({
                sortModel: newModel,
                paging: newPaging
            });

            fetchReportLines(newPaging, filters);

        }
    }

    render() {
        const { classes, isLoading, fetchReportLines, reportLines } = this.props;
        const { filters, menuItem, paging, columnVisibilityModel } = this.state;

        return (
            <MainLayout menuItem={menuItem}
                routes={
                    [{
                        name: 'go to Reports',
                        url: `${UrlConstants.REPORTS}`
                    }]}
            >
                <Container maxWidth="xl" className={classes.container}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <ReportLineFiltersComponent
                                    actions={[{
                                        text: messages.downloadCSV,
                                        icon: 'download',
                                        onClick: this.exportCSV
                                    }]}
                                    applyFilters={this.applyFilters} setDefaultState={this.setDefaultState} filters={filters} onChange={this.onChange} />
                                <ReportLineList
                                    rows={reportLines} isLoading={isLoading}
                                    ref={this.reportLineRef}
                                    onPageChange={(nextPage: number, nextSize: number) => {
                                        const newPage = {
                                            ...paging,
                                            page: nextPage,
                                            size: nextSize
                                        };
                                        fetchReportLines(newPage, filters);
                                        this.setState({ paging: newPage });
                                    }}
                                    onSortModelChange={(sortModel: GridSortModel) => this._onSortChange(sortModel)}
                                    columnVisibilityModel={columnVisibilityModel}
                                    onColumnVisibilityModelChange={(newModel: GridColumnVisibilityModel) =>
                                        this.setState({
                                            columnVisibilityModel: newModel
                                        })
                                    }
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </MainLayout>
        );
    }
}

export default withStyles(styles)(connect(mapStoreToProps, mapDispatchToProps)(ReportLines));