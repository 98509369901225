import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import HttpErrorResponse from '../interfaces/common/httpErrorResponse';
import Paging from '../interfaces/common/paging';
import Pagination from '../interfaces/common/pagination';
import { RootState } from '../setup';
import OrderNotYetInvoiced from '../interfaces/output/orderNotYetInvoiced';
import OrdersNotYetInvoicedFilters from '../interfaces/filters/orderNotYetInvoicedFilters';
import CalculatedPrice from '../interfaces/output/calculatedPrice';
import CalculatedPriceListFilters from '../interfaces/filters/calculatedPriceFilters';
import ProvisionFilters from '../interfaces/filters/kickbackAgreementFilters';
import Provision from '../interfaces/output/prevision';
import KickbackAgreementFilters from '../interfaces/filters/kickbackAgreementFilters';
import KickBackPosten from '../interfaces/output/kickBackPosten';
import ReportHeaderPivoted from '../interfaces/output/ReportHeaderPivoted';
import ReportHeader from '../interfaces/output/ReportHeader';
import ReportLine from '../interfaces/output/ReportLine';
import ReportHeaderPivotedFilters from '../interfaces/filters/reportHeaderPivotedFilters';
import ReportHeaderFilters from '../interfaces/filters/reportHeaderFilters';
import ReportHeaderLineFilters from '../interfaces/filters/reportHeaderLineFilters';
import ReportLineFilters from '../interfaces/filters/reportHeaderLineFilters';
import ReportCustomerTerms from '../interfaces/output/ReportCustomerTerms';
import ReporCustomerTermsFilters from '../interfaces/filters/reportCustomerTermsFilters';

export type ReportsState = {
    isLoadingOrdersNotYetInvoiced: boolean;
    ordersNotYetInvoiced: Pagination<OrderNotYetInvoiced>;
    error: HttpErrorResponse | undefined;
    isLoadingCalculatedPrice: boolean;
    calculatedPrice: Pagination<CalculatedPrice>;
    orderNotYetInvoicedFullReport: Pagination<OrderNotYetInvoiced>;
    isLoadingOrderNotYetInvoicedFullReport: boolean;
    isExportKickbackAgreementPostenCSV: boolean;
    isExportProvisionsCSV: boolean;
    isLoadingProvisions: boolean;
    provisions: Pagination<Provision>;
    isLoadingKickBackPosten: boolean;
    kickBackPostens: Pagination<KickBackPosten>;
    reportHeadersPivoted: Pagination<ReportHeaderPivoted>;
    isLoadingReportHeadersPivoted: boolean;
    reportHeaders: Pagination<ReportHeader>;
    isLoadingReportHeaders: boolean;
    reportLines: Pagination<ReportLine>;
    isLoadingReportLines: boolean;
    reportLine: ReportLine;
    isLoadingReportLine: boolean;
    isExportReportLines: boolean;
    isExportingCustomerTerms: boolean;
    isLoadingCustomerTerms: boolean;
    reportCustomerTerms: Pagination<ReportCustomerTerms>;
};

const initialState: ReportsState = {
    ordersNotYetInvoiced: { content: [] as Array<OrderNotYetInvoiced> } as Pagination<OrderNotYetInvoiced>,
    isLoadingOrdersNotYetInvoiced: false,
    error: undefined,
    isLoadingCalculatedPrice: false,
    calculatedPrice: { content: [] as Array<CalculatedPrice> } as Pagination<CalculatedPrice>,
    orderNotYetInvoicedFullReport: { content: [] as Array<OrderNotYetInvoiced> } as Pagination<OrderNotYetInvoiced>,
    isLoadingOrderNotYetInvoicedFullReport: false,
    isExportKickbackAgreementPostenCSV: false,
    isExportProvisionsCSV: false,
    isLoadingProvisions: false,
    provisions: { content: [] as Array<Provision> } as Pagination<Provision>,
    kickBackPostens: { content: [] as Array<KickBackPosten> } as Pagination<KickBackPosten>,
    isLoadingKickBackPosten: false,
    reportHeadersPivoted: { content: [] as Array<ReportHeaderPivoted> } as Pagination<ReportHeaderPivoted>,
    isLoadingReportHeadersPivoted: false,
    reportHeaders: { content: [] as Array<ReportHeader> } as Pagination<ReportHeader>,
    isLoadingReportHeaders: false,
    reportLines: { content: [] as Array<ReportLine> } as Pagination<ReportLine>,
    isLoadingReportLines: false,
    reportLine: {} as ReportLine,
    isLoadingReportLine: false,
    isExportReportLines: false,
    isExportingCustomerTerms: false,
    isLoadingCustomerTerms: false,
    reportCustomerTerms: { content: [] as Array<ReportCustomerTerms> } as Pagination<ReportCustomerTerms>
};

export const reportsSlice = createSlice({
    name: 'reports',

    initialState,
    reducers: {
        fetchOrdersNotYetInvoiced: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) => {
            state.isLoadingOrdersNotYetInvoiced = true;
        },
        fetchOrdersNotYetInvoicedSuccess: (state: ReportsState, action: PayloadAction<Pagination<OrderNotYetInvoiced>>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.ordersNotYetInvoiced = action.payload;
        },
        fetchOrdersNotYetInvoicedError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrdersNotYetInvoiced = false;
            state.error = action.payload;
        },
        resetOrdersNotYetInvoiced: (state: ReportsState) => {
            state.ordersNotYetInvoiced = initialState.ordersNotYetInvoiced;
        },
        fetchOrdersNotYetInvoicedFullReport: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: OrdersNotYetInvoicedFilters }>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = true;
        },
        fetchOrdersNotYetInvoicedFullReportSuccess: (state: ReportsState, action: PayloadAction<Pagination<OrderNotYetInvoiced>>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = false;
            state.orderNotYetInvoicedFullReport = action.payload;
        },
        fetchOrdersNotYetInvoicedFullReportError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingOrderNotYetInvoicedFullReport = false;
            state.error = action.payload;
        },
        resetOrdersNotYetInvoicedFullReport: (state: ReportsState) => {
            state.orderNotYetInvoicedFullReport = initialState.ordersNotYetInvoiced;
        },
        fetchCalculatedPrice: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: CalculatedPriceListFilters }>) => {
            state.isLoadingCalculatedPrice = true;
        },
        fetchCalculatedPriceSuccess: (state: ReportsState, action: PayloadAction<Pagination<CalculatedPrice>>) => {
            state.isLoadingCalculatedPrice = false;
            state.calculatedPrice = action.payload;
        },
        fetchCalculatedPriceError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCalculatedPrice = false;
            state.error = action.payload;
        },
        resetCalculatedPrice: (state: ReportsState) => {
            state.calculatedPrice = initialState.calculatedPrice;
        },
        exportProvisionsCSV: (state: ReportsState, _action: PayloadAction<{ searchTerm?: string }>) => {
            state.isExportProvisionsCSV = true;
        },
        exportProvisionsCSVSuccess: (state: ReportsState) => {
            state.isExportProvisionsCSV = false;
        },
        exportProvisionsCSVError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportProvisionsCSV = false;
            state.error = action.payload;
        },
        exportKickbackAgreementPostenCSV: (state: ReportsState, _action: PayloadAction<{ filters?: ProvisionFilters }>) => {
            state.isExportKickbackAgreementPostenCSV = true;
        },
        exportKickbackAgreementPostenCSVSuccess: (state: ReportsState) => {
            state.isExportKickbackAgreementPostenCSV = false;
        },
        exportKickbackAgreementPostenCSVError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportKickbackAgreementPostenCSV = false;
            state.error = action.payload;
        },
        fetchProvisions: (state: ReportsState, _action: PayloadAction<{ paging: Paging, searchTerm?: string }>) => {
            state.isLoadingProvisions = true;
        },
        fetchProvisionsSuccess: (state: ReportsState, action: PayloadAction<Pagination<Provision>>) => {
            state.isLoadingProvisions = false;
            state.provisions = action.payload;
        },
        fetchProvisionsError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingProvisions = false;
            state.error = action.payload;
        },
        resetProvisions: (state: ReportsState) => {
            state.provisions = initialState.provisions;
        },
        fetchKickBackPosten: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: KickbackAgreementFilters }>) => {
            state.isLoadingKickBackPosten = true;
        },
        fetchKickBackPostenSuccess: (state: ReportsState, action: PayloadAction<Pagination<KickBackPosten>>) => {
            state.isLoadingKickBackPosten = false;
            state.kickBackPostens = action.payload;
        },
        fetchKickBackPostenError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingKickBackPosten = false;
            state.error = action.payload;
        },
        resetKickBackPosten: (state: ReportsState) => {
            state.kickBackPostens = initialState.kickBackPostens;
        },
        fetchReportHeaderPivoted: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: ReportHeaderPivotedFilters }>) => {
            state.isLoadingReportHeadersPivoted = true;
        },
        fetchReportHeaderPivotedSuccess: (state: ReportsState, action: PayloadAction<Pagination<ReportHeaderPivoted>>) => {
            state.isLoadingReportHeadersPivoted = false;
            state.reportHeadersPivoted = action.payload;
        },
        fetchReportHeaderPivotedError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingReportHeadersPivoted = false;
            state.error = action.payload;
        },
        resetReportHeaderPivoted: (state: ReportsState) => {
            state.reportHeadersPivoted = initialState.reportHeadersPivoted;
        },
        fetchReportHeader: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: ReportHeaderFilters }>) => {
            state.isLoadingReportHeaders = true;
        },
        fetchReportHeaderSuccess: (state: ReportsState, action: PayloadAction<Pagination<ReportHeader>>) => {
            state.isLoadingReportHeaders = false;
            state.reportHeaders = action.payload;
        },
        fetchReportHeaderError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingReportHeaders = false;
            state.error = action.payload;
        },
        resetReportHeader: (state: ReportsState) => {
            state.reportHeaders = initialState.reportHeaders;
        },
        fetchReportLines: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: ReportLineFilters }>) => {
            state.isLoadingReportLines = true;
        },
        fetchReportLinesSuccess: (state: ReportsState, action: PayloadAction<Pagination<ReportLine>>) => {
            state.isLoadingReportLines = false;
            state.reportLines = action.payload;
        },
        fetchReportLinesError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingReportLines = false;
            state.error = action.payload;
        },
        resetReportLines: (state: ReportsState) => {
            state.reportLines = initialState.reportLines;
        },
        fetchReportLineById: (state: ReportsState, _action: PayloadAction<number>) => {
            state.isLoadingReportLine = true;
        },
        fetchReportLineByIdSuccess: (state: ReportsState, action: PayloadAction<ReportLine>) => {
            state.isLoadingReportLine = false;
            state.reportLine = action.payload;
        },
        resetReportLine: (state: ReportsState) => {
            state.reportLine = initialState.reportLine;
        },
        fetchReportLineByIdError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingReportLine = false;
            state.error = action.payload;
        },
        exportReportLines: (state: ReportsState, _action: PayloadAction<{ filters?: ReportLineFilters }>) => {
            state.isExportReportLines = true;
        },
        exportReportLinesSuccess: (state: ReportsState) => {
            state.isExportReportLines = false;
        },
        exportReportLinesError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportReportLines = false;
            state.error = action.payload;
        },
        exportReportCustomerTerms: (state: ReportsState, _action: PayloadAction<{ filters?: ReportCustomerTerms }>) => {
            state.isExportingCustomerTerms = true;
        },
        exportReportCustomerTermsSuccess: (state: ReportsState) => {
            state.isExportingCustomerTerms = false;
        },
        exportReportCustomerTermsError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isExportingCustomerTerms = false;
            state.error = action.payload;
        },
        fetchReportCustomerTerms: (state: ReportsState, _action: PayloadAction<{ paging: Paging, filters?: ReporCustomerTermsFilters }>) => {
            state.isLoadingCustomerTerms = true;
        },
        fetchReportCustomerTermSuccess: (state: ReportsState, action: PayloadAction<Pagination<ReportCustomerTerms>>) => {
            state.isLoadingCustomerTerms = false;
            state.reportCustomerTerms = action.payload;
        },
        fetchReportCustomerTermsError: (state: ReportsState, action: PayloadAction<HttpErrorResponse>) => {
            state.isLoadingCustomerTerms = false;
            state.error = action.payload;
        },
        resetReportCustomerTerms: (state: ReportsState) => {
            state.reportCustomerTerms = initialState.reportCustomerTerms;
        }
    }
});

export const { fetchOrdersNotYetInvoiced, fetchOrdersNotYetInvoicedError, fetchOrdersNotYetInvoicedSuccess, resetOrdersNotYetInvoiced,
    fetchCalculatedPrice, fetchCalculatedPriceError, fetchCalculatedPriceSuccess, resetCalculatedPrice,
    fetchOrdersNotYetInvoicedFullReport, fetchOrdersNotYetInvoicedFullReportError, fetchOrdersNotYetInvoicedFullReportSuccess, resetOrdersNotYetInvoicedFullReport,
    exportKickbackAgreementPostenCSV, exportKickbackAgreementPostenCSVError, exportKickbackAgreementPostenCSVSuccess, exportProvisionsCSV, exportProvisionsCSVError,
    exportProvisionsCSVSuccess, fetchProvisions, fetchProvisionsSuccess, fetchProvisionsError, fetchKickBackPosten,
    fetchKickBackPostenError, fetchKickBackPostenSuccess, resetKickBackPosten, resetProvisions, fetchReportLines, fetchReportLinesSuccess, fetchReportHeader,
    fetchReportHeaderError, fetchReportHeaderPivoted, fetchReportHeaderPivotedError, fetchReportHeaderPivotedSuccess, fetchReportHeaderSuccess, fetchReportLineById,
    fetchReportLinesError, fetchReportLineByIdError, fetchReportLineByIdSuccess, resetReportHeaderPivoted, resetReportHeader, resetReportLines,
    exportReportLines, exportReportLinesError, exportReportLinesSuccess, resetReportLine, exportReportCustomerTerms, exportReportCustomerTermsError,
    exportReportCustomerTermsSuccess, fetchReportCustomerTermSuccess, fetchReportCustomerTerms, fetchReportCustomerTermsError, resetReportCustomerTerms }
    = reportsSlice.actions;

export const isLoadingOrdersNotYetInvoiced = (state: RootState): boolean => state.reportsReducer.isLoadingOrdersNotYetInvoiced;
export const getOrdersNotYetInvoiced = (state: RootState): Pagination<OrderNotYetInvoiced> => state.reportsReducer.ordersNotYetInvoiced;
export const isLoadingCalculatedPrice = (state: RootState): boolean => state.reportsReducer.isLoadingCalculatedPrice;
export const getCalculatedPrice = (state: RootState): Pagination<CalculatedPrice> => state.reportsReducer.calculatedPrice;
export const isLoadingOrdersNotYetInvoicedFullReport = (state: RootState): boolean => state.reportsReducer.isLoadingOrderNotYetInvoicedFullReport;
export const getOrdersNotYetInvoicedFullReport = (state: RootState): Pagination<OrderNotYetInvoiced> => state.reportsReducer.orderNotYetInvoicedFullReport;
export const isExportProvisionsCSV = (state: RootState): boolean => state.reportsReducer.isExportProvisionsCSV;
export const isExportKickbackAgreementPostenCSV = (state: RootState): boolean => state.reportsReducer.isExportKickbackAgreementPostenCSV;
export const isLoadingProvisions = (state: RootState): boolean => state.reportsReducer.isLoadingProvisions;
export const getProvisions = (state: RootState): Pagination<Provision> => state.reportsReducer.provisions;
export const isLoadingKickBackPosten = (state: RootState): boolean => state.reportsReducer.isLoadingKickBackPosten;
export const getKickBackPosten = (state: RootState): Pagination<KickBackPosten> => state.reportsReducer.kickBackPostens;
export const getReportHeadersPivoted = (state: RootState): Pagination<ReportHeaderPivoted> => state.reportsReducer.reportHeadersPivoted;
export const isLoadingReportHeaderPivoted = (state: RootState): boolean => state.reportsReducer.isLoadingReportHeadersPivoted;
export const getReportHeaders = (state: RootState): Pagination<ReportHeader> => state.reportsReducer.reportHeaders;
export const isLoadingReportHeader = (state: RootState): boolean => state.reportsReducer.isLoadingReportHeaders;
export const getReportLines = (state: RootState): Pagination<ReportLine> => state.reportsReducer.reportLines;
export const isLoadingReportLines = (state: RootState): boolean => state.reportsReducer.isLoadingReportLines;
export const getReportLine = (state: RootState): ReportLine => state.reportsReducer.reportLine;
export const isLoadingReportLine = (state: RootState): boolean => state.reportsReducer.isLoadingReportLine;
export const isExportReportLines = (state: RootState): boolean => state.reportsReducer.isExportReportLines;
export const getReportCustomerTerms = (state: RootState): Pagination<ReportCustomerTerms> => state.reportsReducer.reportCustomerTerms;
export const isLoadingReportCustomerTrems = (state: RootState): boolean => state.reportsReducer.isLoadingCustomerTerms;
export const isExportingCustomerTerms = (state: RootState): boolean => state.reportsReducer.isExportingCustomerTerms;
export default reportsSlice.reducer;