import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { GridColDef, GridColumnVisibilityModel, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useIntl } from 'react-intl';
import LanguageUtils from '../../../utils/LanguageUtils';
import TableUtils from '../../../utils/tableUtils';
import HelptextConstantsEnum from '../../../constants/helptextConstantsEnum';
import DataGridComponent from '../../common/dataGrid';
import NumericFormat from 'react-number-format';
import CustomLink from '../../common/customLink';
import UrlConstants from '../../../constants/UrlConstants';
import CustomStringLink from '../../common/customStringLink';

interface ReportLinesListProps {
    classes?: any;
    checkSelection?: any;
    isLoading?: boolean;
    multipleSelection?: boolean;
    componentsProps?: any;
    onPageChange?: any;
    rows?: any;
    applyRowFn?: any;
    hiddenColumns?: Array<string>;
    onSortModelChange?: any;
    columnVisibilityModel?: GridColumnVisibilityModel;
    onColumnVisibilityModelChange?: any;
    setCurrentComponentId?: any;
    disableLinks?: boolean;
}

const messages = {
    gtsCustomerNo: LanguageUtils.createMessage('gts customer number'),
    fromPlace: LanguageUtils.createMessage('from place'),
    toPlace: LanguageUtils.createMessage('to place'),
    amount: LanguageUtils.createMessage('Amount'),
    totalPrice: LanguageUtils.createMessage('Total price')
};

const ReportLinesList = forwardRef((props: ReportLinesListProps, ref: any): JSX.Element => {
    const { componentsProps, isLoading, checkSelection, onPageChange, rows, hiddenColumns, multipleSelection, onSortModelChange,
        columnVisibilityModel, onColumnVisibilityModelChange, setCurrentComponentId } = props;

    const defaultFn = (cellValues: GridRenderCellParams | GridValueGetterParams) => cellValues.row;

    const applyRowFn = props.applyRowFn || defaultFn;

    const dataGridRef = useRef();

    useImperativeHandle(ref, () => ({
        resetDataGridPage() {
            if(dataGridRef !== null && dataGridRef !== undefined && dataGridRef.current !== null && dataGridRef.current !== undefined) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                dataGridRef.current.resetPage();
            }
        }
    }));

    useEffect(() => {
        if(setCurrentComponentId !== undefined) {
            setCurrentComponentId(HelptextConstantsEnum.REPORT_KICKBACK_POSTEN);
        }
    }, []);

    function generateColumns(): GridColDef[] {
        const intl = useIntl();

        return [
            {
                field: 'gtsCustomerNo',
                headerName: intl.formatMessage(messages.gtsCustomerNo),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.gtsCustomerNo}
                        link={`/#/${UrlConstants.REPORT_LINE}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('gtsCustomerNo', hiddenColumns)
            },
            {
                field: 'fromPlace',
                headerName: `${intl.formatMessage(messages.fromPlace)}`,
                flex: 0.5,
                align: 'left',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.fromPlace}
                        link={`/#/${UrlConstants.REPORT_LINE}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('fromPlace', hiddenColumns)
            },
            {
                field: 'toPlace',
                headerName: intl.formatMessage(messages.toPlace),
                flex: 0.5,
                align: 'left',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.toPlace}
                        link={`/#/${UrlConstants.REPORT_LINE}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('toPlace', hiddenColumns)
            },
            {
                field: 'amount',
                headerName: intl.formatMessage(messages.amount),
                flex: 1,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) => {
                    return <CustomStringLink align={'right'} value={applyRowFn(param)?.amount}
                        link={`/#/${UrlConstants.REPORT_LINE}/${applyRowFn(param)?.id}`} />;
                },
                hide: hiddenColumns && TableUtils.hideColumn('amount', hiddenColumns)
            },
            {
                field: 'totalPrice',
                headerName: intl.formatMessage(messages.totalPrice),
                flex: 0.5,
                align: 'right',
                headerAlign: 'right',
                sortable: true,
                renderCell: (param: GridRenderCellParams) =>
                    <CustomLink align={'right'} link={`/#/${UrlConstants.REPORT_LINE}/${applyRowFn(param)?.id}`}>
                        <NumericFormat value={applyRowFn(param)?.totalPrice} displayType={'text'} thousandSeparator={true} />
                    </CustomLink>,
                hide: hiddenColumns && TableUtils.hideColumn('totalPrice', hiddenColumns)
            }
        ];
    }

    return (
        <DataGridComponent getRowId={(row) => row?.amount + Math.random()} rows={rows} multipleSelection={multipleSelection} columns={generateColumns()}
            ref={dataGridRef} componentsProps={componentsProps} isLoading={isLoading}
            checkSelection={checkSelection} onPageChange={onPageChange} onSortModelChange={onSortModelChange}
            columnVisibilityModel={columnVisibilityModel} onColumnVisibilityModelChange={onColumnVisibilityModelChange} />
    );
});

export default ReportLinesList;
